import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './Description.module.scss'
import posed from 'react-pose'
import { FormattedMessage } from 'react-intl'

const Content = posed.div({
  closed: { height: '225px' },
  opened: { height: 'auto' },
})

class Description extends Component {
  state = { isOpen: false }

  toggleDescription = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { html } = this.props
    return (
      <>
        {html.length > 500 ? (
          <>
            <Content className={styles.content} pose={this.state.isOpen ? 'opened' : 'closed'}>
              <article className={styles.article} dangerouslySetInnerHTML={{ __html: html }} />
              {!this.state.isOpen && <div className={styles.gradient} />}
            </Content>

            <button onClick={this.toggleDescription} className={styles.showMore}>
              {this.state.isOpen ? <FormattedMessage id="home.showLess" /> : <FormattedMessage id="home.fullNews" />}
            </button>
          </>
        ) : (
          <article className={styles.article} dangerouslySetInnerHTML={{ __html: html }} />
        )}
      </>
    )
  }
}

Description.propTypes = {
  html: PropTypes.string,
}

export default Description
