import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './NewsList.module.scss'
import NewsSingle from 'src/components/NewsSingle'
import Ad from 'src/components/Ad'
import SortPanel from 'src/components/SortPanel'
import { injectIntl } from 'react-intl'
import { handleSortByOldDate, handleSortByNewDate, formateDate } from 'src/utils'

const insertAdEveryNthNum = 5

class NewsList extends Component {
  state = { news: this.props.news }

  handleSort = (ev) => {
    const news = this.props.news
    formateDate(news, 'publishdate')
    let activeOption = ev.target.value
    switch (activeOption) {
      case 'old':
        this.setState({ news: handleSortByOldDate(news) })
        break
      case 'new':
        this.setState({ news: handleSortByNewDate(news) })
        break
      default:
    }
  }

  insertAd(arr, num) {
    const arrCopy = [...arr]
    let step = 1
    for (let i = 0; i < arrCopy.length; i++) {
      if (i === step - 1) {
        arrCopy[i].node.addMargin = true
      }
      if (i === step) {
        arrCopy.splice(i, 0, { node: { type: 'emptyDiv', newId: i } })
        step += num + 1
      }
    }
    return arrCopy
  }

  render() {
    const { locale, promotion, intl } = this.props
    const { news } = this.state

    const newsWithAd = this.insertAd(news, insertAdEveryNthNum)

    const options = [
      { type: 'new', text: intl.formatMessage({ id: 'home.fromNewToOld' }) },
      { type: 'old', text: intl.formatMessage({ id: 'home.fromOldToNew' }) },
    ]

    return (
      <>
        <SortPanel
          title={intl.formatMessage({ id: 'home.AllNews' })}
          options={options}
          sortTitle={intl.formatMessage({ id: 'home.Sort' })}
          onGetOption={(ev) => this.handleSort(ev)}
          removeMargin
        />
        <div className={styles.root}>
          <section className={styles.newsWrapper}>
            {newsWithAd.map(({ node }) => {
              return node.type && node.type === 'emptyDiv' ? (
                <div className={styles.adLink} key={node.newId} />
              ) : (
                <NewsSingle item={node} key={node.title} locale={locale} />
              )
            })}
          </section>
          <div className={styles.ad}>
            <Ad locale={locale} ad={promotion} />
          </div>
        </div>
      </>
    )
  }
}

NewsList.displayName = 'NewsFeed.NewsList'

NewsList.propTypes = {
  locale: PropTypes.string,
  promotion: PropTypes.object,
}

export default injectIntl(NewsList)
