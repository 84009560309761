import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PageHelmet from 'src/components/PageHelmet'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import NewsList from 'src/components/NewsList'
import withLayout from 'src/components/withLayout'
import { injectIntl } from 'react-intl'

import 'src/styles/style.scss'

const prepareNews = (news) => news.filter(({ node }) => node.slug !== '_')

class News extends Component {
  render() {
    const {
      data: {
        allDatoCmsNews: { edges },
        promotion,
      },
      locale,
      changeLocale,
      intl,
    } = this.props

    const news = prepareNews(edges)

    return (
      <div>
        <PageHelmet title={intl.formatMessage({ id: 'home.news' })} locale={locale} defer={false} />
        <Header full={true} locale={locale} changeLocale={changeLocale} />
        <NewsList news={news} locale={locale} promotion={promotion} />
        <Footer locale={locale} />
      </div>
    )
  }
}

export const query = graphql`
  query NewsList($locale: String!) {
    allDatoCmsNews(filter: { locale: { eq: $locale } }, sort: { fields: [publishdate], order: DESC }) {
      edges {
        node {
          publishdate(formatString: "DD.MM.YYYY")
          slug
          title
          description
          bodyNode {
            childMarkdownRemark {
              html
            }
          }
          image {
            id
            fluid(maxWidth: 1240, imgixParams: { fm: "jpg", auto: "compress, format" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          author
        }
      }
    }
    promotion: allDatoCmsPromotion(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          autoplayDelay
          slides {
            id
            title
            image {
              url
            }
            link
          }
        }
      }
    }
  }
`

const customProps = {
  localeKey: 'newsList',
}

export default withLayout(customProps)(injectIntl(News))
