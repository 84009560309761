import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './NewsSingle.module.scss'
import Img from 'gatsby-image'
import SharePanel from 'src/components/SharePanel'
import { getBaseUrl } from 'src/utils'
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor'
import Description from './Description'

class NewsSingle extends Component {
  render() {
    const {
      item: {
        slug,
        publishdate,
        title,
        image,
        description,
        bodyNode: {
          childMarkdownRemark: { html },
        },
        addMargin,
      },
      locale,
    } = this.props

    configureAnchors({ offset: -60, scrollDuration: 200 })

    return (
      <ScrollableAnchor id={slug}>
        <article className={classNames(styles.root, addMargin && styles.addMargin)}>
          {title && <h3 className={styles.title}>{title.length > 120 ? title.substring(0, 120) + '...' : title}</h3>}
          <p className={styles.description}>{description}</p>
          <div className={styles.iconsWrapper}>
            <p className={styles.date}>{publishdate}</p>
            <SharePanel
              title={title}
              description={description}
              url={`${getBaseUrl()}${locale}/news-list/${slug}`}
              className={styles.sharePanel}
            />
          </div>
          {image && <Img className={styles.image} fluid={image.fluid} />}
          <Description html={html} />
        </article>
      </ScrollableAnchor>
    )
  }
}

NewsSingle.displayName = 'NewsFeed.NewsSingle'

NewsSingle.propTypes = {
  className: PropTypes.string,
  slug: PropTypes.string,
  publishdate: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.object,
  description: PropTypes.string,
  locale: PropTypes.string,
  html: PropTypes.string,
}

export default NewsSingle
